import React from 'react';
import './Features.css';
import { FcAssistant, FcCalendar, FcAutomatic } from 'react-icons/fc';

function Features() {
  return (
    <div className="feature-section">
      <h2>Run leasing on autopilot.</h2>
      <div className="features">
        <div className="feature">
          <div className="feature-icon">
            <FcAssistant size={48} />
          </div>
          <h3>Response</h3>
          <h4>Instantly replies to all your client queries.</h4>
          <p>
            Integrates with Zillow, Apartments.com, Trulia, Craigslist, and more
            so all your conversations are in one place, with your leasing agent
            doing the work for you.
          </p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FcAutomatic size={48} />
          </div>
          <h3>Automation</h3>
          <h4>Automates follow-ups.</h4>
          <p>
            Prospective tenants ask many of the same repetitive questions. Our
            AI automatically answers these questions, saving you time and
            providing a consistent experience for your tenants.
          </p>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <FcCalendar size={48} />
          </div>
          <h3>Calendar</h3>
          <h4>Connects with your calendar to book tours.</h4>
          <p>
            Our AI automatically tracks your availability and suggests tour
            times to prospective tenants. If someone can't make an existing
            time, we loop you in to find a time that works for everyone.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Features;
