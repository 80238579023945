import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Spinner,
  Text,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

const WhatsAppMessageForm = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [messageType, setMessageType] = useState('text');
  const [textMessage, setTextMessage] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleMessageTypeChange = (event) => {
    setMessageType(event.target.value);
  };

  const handleTextMessageChange = (event) => {
    setTextMessage(event.target.value);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleTemplateLanguageChange = (event) => {
    setTemplateLanguage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      let res;
      if (messageType === 'text') {
        res = await axios.post(
          'https://whatsappwebhook-bri4.onrender.com/send-text',
          { to: mobileNumber, message: textMessage },
        );
      } else {
        res = await axios.post(
          'https://whatsappwebhook-bri4.onrender.com/send-template',
          { to: mobileNumber, templateName, templateLanguage },
        );
      }

      console.log('🚀 ~ handleSubmit ~ res:', res);

      if (res.status === 200) {
        toast.success('Message sent successfully!');
        history.push('/admin/your-messages');
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data || 'An error occurred';
      console.log('🚀 ~ handleSubmit ~ errorMessage:', errorMessage);
      toast.error(errorMessage.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{
        type: 'spring',
        stiffness: 120,
        damping: 10,
        duration: 0.5,
      }}
    >
      <Flex justify="center" align="center" h="100vh" bg="#f6f3fc">
        <Box
          w="100%"
          maxW="2xl"
          px="6"
          py="8"
          bg="white"
          boxShadow="lg"
          rounded="lg"
        >
          <form onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Mobile Number</FormLabel>
              <Input
                placeholder="Mobile Number"
                type="tel"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                mb="4"
              />
              <Text mt="2" color="gray.500">
                Enter 91 before your number if you are from India or your
                country code before your number. ex- 91 0000000000
              </Text>
            </FormControl>

            <FormControl isRequired mt="4">
              <FormLabel>Message Type</FormLabel>
              <Select value={messageType} onChange={handleMessageTypeChange}>
                <option value="text">Text Message</option>
                <option value="template">Template Message</option>
              </Select>
            </FormControl>

            {messageType === 'text' ? (
              <FormControl isRequired mt="4">
                <FormLabel>Text Message</FormLabel>
                <Input
                  placeholder="Your message"
                  value={textMessage}
                  onChange={handleTextMessageChange}
                />
              </FormControl>
            ) : (
              <>
                <FormControl isRequired mt="4">
                  <FormLabel>Template Name</FormLabel>
                  <Input
                    placeholder="Template Name"
                    value={templateName}
                    onChange={handleTemplateNameChange}
                  />
                </FormControl>

                <FormControl isRequired mt="4">
                  <FormLabel>Template Language</FormLabel>
                  <Input
                    placeholder="Template Language"
                    value={templateLanguage}
                    onChange={handleTemplateLanguageChange}
                  />
                </FormControl>
              </>
            )}

            <Flex justify="center" mt="6">
              <Button
                variant="brand"
                w="100%"
                colorScheme="blue"
                type="submit"
                isLoading={loading}
                isDisabled={loading}
                loadingText="Please wait..."
                spinner={<Spinner size="sm" />}
              >
                Send Your Message
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </motion.div>
  );
};

export default WhatsAppMessageForm;
