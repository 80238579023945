import React from 'react';
import HeroSection from './Components/HeroSection/HeroSection';
import Features from './Components/Features/Features';
import Navbar from './Components/Navbar/Navbar';
import Footer from 'components/footer/FooterAdmin';
import Graph from './Components/HeroSection/Graph/Graph';
import BrandUsing from './Components/HeroSection/BrandsUsing/BrandsUsing';
import frame1 from 'assets/img/LandingPage/frame1.png';
import frame2 from 'assets/img/LandingPage/frame2.png';
import frame3 from 'assets/img/LandingPage/frame3.png';
function LandingPage() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Features />
      <Footer />
    </>
  );
}

export default LandingPage;
