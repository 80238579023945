import React from 'react';
import './HeroSection.css';
import PixoLabsImage from 'assets/img/LandingPage/pixolabs.png';

function HeroSection() {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1>
          Your personal AI agent that works 24*7 to convert leads to clients
        </h1>
        <ul className="bullet-points">
          <li>
            Customize your AI co-pilot: You can design how you want AI to answer
            questions on your behalf.{' '}
          </li>
          <li>
            Track analytics: 1 dashboard to monitor all your properties.
            Identify what works and what doesn't.
          </li>
        </ul>
        <div className="hero-buttons">
          <a
            className="hero-button primary"
            href="https://calendly.com/ressi-54/30min?back=1"
            target="_blank"
          >
            Book a Demo
          </a>
        </div>
      </div>
      <div className="hero-image">
        <img src={PixoLabsImage} alt="AI Chat Interface" />
      </div>
    </div>
  );
}

export default HeroSection;
