import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import MessagesCustomModal from './MessagesCustomModal';

const YourMessages = () => {
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    const interval = setInterval(fetchMessages, 3000);
    return () => clearInterval(interval);
  }, [selectedContact]);

  const fetchContacts = async () => {
    try {
      const response = await fetch('https://whatsappwebhook-bri4.onrender.com/participants');
      const data = await response.json();
      setContacts(data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const fetchMessages = async () => {
    if (!selectedContact) return;

    try {
      const response = await fetch(`https://whatsappwebhook-bri4.onrender.com/conversation/${selectedContact.participantId}`);
      const data = await response.json();
      const formattedMessages = data.map((msg) => ({
        id: msg.messageId,
        text: msg.content,
        sender: msg.from === 'your_phone_number_id' ? 'user' : 'reply',
      }));
      setMessages(formattedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async (message) => {
    if (message.trim() === '') return;

    const newMessage = {
      id: messages.length + 1,
      text: message,
      sender: 'user',
    };
    setMessages([...messages, newMessage]);
    setMessageInput('');

    try {
      await fetch('https://whatsappwebhook-bri4.onrender.com/send-text', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: selectedContact.participantId,
          message: message,
        }),
      });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleFirstMessage = (contact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAllow = () => {
    setIsModalOpen(false);
    handleSendMessage(
      '',
    );
  };

  return (
    <Flex
      direction="column"
      h="100vh"
      pt={{ base: '130px', md: '80px', xl: '80px' }}
    >
      <Flex h="40%" direction="column" mb="4">
        <Text fontSize="2xl" mb="4">
          Contacts
        </Text>
        <VStack spacing="4" align="stretch">
          {contacts.map((contact) => (
            <Flex key={contact.participantId} align="center" justify="space-between">
              <Text>{contact.name ? contact.name : contact.participantId}</Text>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() => handleFirstMessage(contact)}
              >
                Show Messages
              </Button>
            </Flex>
          ))}
        </VStack>
      </Flex>
      <Box
        h="60%"
        bg="gray.100"
        p="4"
        rounded="md"
        shadow="md"
        display="flex"
        flexDirection="column"
      >
        <Text fontSize="2xl" mb="4">
          Chat with {selectedContact ? (selectedContact.name ? selectedContact.name : selectedContact.participantId) : '...'}
        </Text>
        <Box bg="white" p="4" rounded="md" flex="1" overflowY="auto">
          {messages.map((message) => (
            <Flex
              key={message.id}
              mb="2"
              justifyContent={
                message.sender === 'user' ? 'flex-end' : 'flex-start'
              }
            >
              <Box
                p="2"
                color="black"
                bg={message.sender === 'user' ? 'blue.100' : '#f0f0f0'}
                rounded="lg"
                borderRadius={
                  message.sender === 'user'
                    ? '15px 15px 0 15px'
                    : '15px 15px 15px 0'
                }
                maxW="50%"
              >
                {message.text}
              </Box>
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </Box>
        {selectedContact && (
          <Flex
            mt="4"
            as="form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage(messageInput);
            }}
          >
            <FormControl mr="4" flex="1">
              <Input
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Type your message..."
              />
            </FormControl>
            <Button type="submit" colorScheme="blue">
              Send
            </Button>
          </Flex>
        )}
      </Box>

      <MessagesCustomModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAllow={handleAllow}
      />
    </Flex>
  );
};

export default YourMessages;
